import React from "react";

import clsx from "clsx";
import PropTypes from "prop-types";

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80,
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  image: {
    "& > img": {
      maxWidth: 400,
      height: "auto",
      backfaceVisibility: "hidden",
      boxShadow: theme.shadows[16],
    },
  },
  bottomItem: {
    paddingBottom: 80,
  },
  paddingItem: {
    paddingLeft: 30,
  },
}));

const FeatureBreakdown = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
          className={clsx(classes.bottomItem)}
        >
          Features
        </Typography>

        <Box width="100%" alignItems="center" alignContent="center">
          <Grid
            container
            spacing={4}
            className={clsx(classes.bottomItem)}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom color="textPrimary">
                Cross-Platform
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Let users from iOS, Android, Playstation, PC, Nintendo, etc. all
                play and compete together
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <picture className={classes.image}>
                  {/* <source srcset="/static/home/Gain.webp" type="image/webp"/> */}
                  <img src="/static/home/cross-platform.png" alt="Reports" />
                </picture>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            className={clsx(classes.bottomItem)}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <div>
                <picture className={classes.image}>
                  <img src="/static/home/leaderboard.gif" alt="Strategies" />
                </picture>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom color="textPrimary">
                Pre-Build leaderboard
              </Typography>
              <Grid item xs>
                <Typography variant="body1" color="textPrimary">
                  End arguments over who is the top of your app. By creating
                  customisable leaderboards with GameBuddy. Easy to integrate
                  API REST and the First rank rules.<br></br>
                </Typography>
              </Grid>

              <Typography variant="body1" color="textPrimary">
                <ul className={clsx(classes.paddingItem)}>
                  <li>
                    Flexible: Unlimited fields. Filter by levels. Sort by
                    anything. Whatever your game needs.
                  </li>
                  <li>
                    Retention: Leaderboards are a great way to add some
                    competition into your game and increase retention
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            className={clsx(classes.bottomItem)}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <div>
                <picture className={classes.image}>
                  {/* <source srcset="/static/home/Gain.webp" type="image/webp"/> */}
                  <img src="/static/home/dashboard.png" alt="Reports" />
                </picture>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom color="textPrimary">
                Players Auth and Moderation
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Authorize and moderate players with our build-in authorizer.
                Platform Independent
                <ul className={clsx(classes.paddingItem)}>
                  <li>
                    Platform Independent: Let players sign in with the same
                    account on any platform Anonymous Accounts
                  </li>
                  <li>
                    Anonymous Accounts: For your casual players, let them sign
                    up with just a username. Connect to an email and password
                    later.
                  </li>
                  <li>
                    Access control: Automatically integrates with other
                    GameBuddy components to make sure your backend is secure.
                    Ban any bad actors directly through our UI.
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

FeatureBreakdown.propTypes = {
  className: PropTypes.string,
};

export default FeatureBreakdown;
