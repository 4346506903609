import React from 'react';
//import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  },
  bottomItem:{
    paddingBottom: 80
  },
  bottomSpacing:{
    paddingBottom: 12
  }
}));

const Pricing = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      
      <Box mt="128px">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
          className={clsx(classes.bottomSpacing)}
        >
          Pricing
        </Typography>
        <Container maxWidth="lg">
          
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={classes.product}
                elevation={1}
              >
                <Typography
                  component="h3"
                  gutterBottom
                  variant="h2"
                  color="textSecondary"
                >
                  Free Backend
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="textPrimary"
                  >
                    $0
                  </Typography>
                </div>
                <Typography
                  variant="overline"
                  color="textSecondary"
                >
                  No trial limits. Free forever
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  100 players
                  <br />
                  5 leaderboards
                  <br />
                  3 games
                  <br />
                  Performance Reports
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  // component={RouterLink}
                  href="https://n03mn29ysq6.typeform.com/to/uwku933Q"
                >
                  Get Started
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product)}
                elevation={1}
              >
                <Typography
                  component="h3"
                  gutterBottom
                  variant="h2"
                  color="inherit"
                >
                  Backend Plus
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="inherit"
                  >
                    $10
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="inherit"
                  >
                    /month
                  </Typography>
                </div>
                <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="textSecondary"
                >
                  $10 /month 
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color="inherit"
                >
                  100K players
                  <br />
                  10 leaderboards (10,000 free API calls and $.008 per extra API call)
                  <br />
                  10 games
                  <br />
                  Player performance reports
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  // component={RouterLink}
                  href="https://n03mn29ysq6.typeform.com/to/uwku933Q"
                >
                  Get Started
                </Button>
                <Typography
                  variant="overline"
                  color="textSecondary"
                  mt={2}
                >

                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product, classes.recommendedProduct)}
                elevation={1}
              >
                <Typography
                  component="h3"
                  gutterBottom
                  variant="h2"
                  color="textPrimary"
                >
                  Backend Premium
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="textPrimary"
                  >
                    $99
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    /month 
                  </Typography>
                </div>
                <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="textPrimary"
                >
                  $99 /month
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  1 million players
                  <br />
                  Unlimited leaderboards (100K free API calls and $.004 per extra API call)
                  <br />
                  Unlimited games
                  <br />
                  Player performance Reports
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  // component={RouterLink}
                  href="https://n03mn29ysq6.typeform.com/to/uwku933Q"
                >
                  Get Started
                </Button>
                <Typography
                  variant="overline"
                  color="textPrimary"
                  mt={2}
                >
                  *7 day free trial with no credit card required
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          
        </Container>
        <Box
          display="flex" 
          alignItems="center"
          justifyContent="center"
          mt={6}
        >
          <Button
            color="primary"
            variant="contained"
            align="center"
            // component={RouterLink}
            href="https://n03mn29ysq6.typeform.com/to/uwku933Q"
          >
            More Details
          </Button>
        </Box>
        
      </Box>
    </div>
  );
};

Pricing.propTypes = {
  className: PropTypes.string
};

export default Pricing;
