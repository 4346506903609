import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';

import { CheckSquare as ApplyIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 80,
    paddingBottom: 80
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  text: {
    marginTop: theme.spacing(2)
  }
}));

const Affiliate = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="secondary"
        >
          Like what you see?
        </Typography>
        <Typography
          className={clsx(classes.text)}
          variant="h3"
          align="center"
          color="textPrimary"
        >
          If you'd like to try it out free, we're accepting applications for our beta program.
        </Typography>
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={(e)=>{
              window.location.href = 'https://forms.gle/75fGk2DVxDJ5VcSX8'; 
            }}
            startIcon={
              <SvgIcon fontSize="small">
                <ApplyIcon />
              </SvgIcon>
            }
          >
            Join the beta
          </Button>
        </Box>
      </Container>
    </div>
  );
};

Affiliate.propTypes = {
  className: PropTypes.string
};

export default Affiliate;
