import React from "react";
//import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80,
    [theme.breakpoints.down("md")]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1),
  },
  image: {
    perspectiveOrigin: "left center",
    transformStyle: "preserve-3d",
    perspective: 2000,
    "& > img": {
      maxWidth: "90%",
      height: "600px",
      transform: "rotateY(-10deg) rotateX(10deg)",
      backfaceVisibility: "hidden",
      //boxShadow: theme.shadows[16],
    },
  },
  shape: {
    position: "absolute",
    top: 0,
    left: 0,
    "& > img": {
      maxWidth: "90%",
      height: "600px",
    },
  },
  particles: {
    background: {
        // color: {
        //     value: "#0d47a1",
        // },
    },
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push",
            },
            onHover: {
                enable: true,
                mode: "repulse",
            },
            resize: true,
        },
        modes: {
            push: {
                quantity: 4,
            },
            repulse: {
                distance: 200,
                duration: 0.4,
            },
        },
    },
    particles: {
        color: {
            value: "#666564",
        },
        links: {
            color: "#666564",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            directions: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 3.2,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 80,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 5 },
        },
    },
    detectRetina: true,
  },
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  const particlesInit = useCallback(async engine => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
}, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
      <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
                fpsLimit: 120,
                particles: {
                    color: {
                        value: "#666564",
                    },
                    links: {
                        color: "#666564",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 3.2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 3000,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
                fullScreen: { enable: true,
                zIndex: 0,}
            }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h1" color="secondary">
                <b>Your Virtual Real Estate Assistant</b>
                <br></br>
              </Typography>
              <Typography variant="h4" color="textPrimary">
                Get a days worth of work done in minutes
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  Streamline your responses and reachouts. Handle more clients, get your 
                  free time back, and be more available with less work.
                </Typography>
              </Box>
              <Box mt={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  // component={RouterLink}
                  // to="/register"
                  // component={RouterLink}
                  href="https://forms.gle/75fGk2DVxDJ5VcSX8"
                >
                 Join the Beta!
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              {/* <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div> */}
              <div>
                <picture className={classes.image}>
                  {/* <source srcset="/static/home/Hero.webp" type="image/webp" /> */}
                  <img src="/static/home/Hero.png" alt="Hero" />
                </picture>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
