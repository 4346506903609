import moment from 'moment';
import mock from 'src/utils/mock';

const notifications = [
  {
    id: '5e8883f7ed1486d665d8be1e',
    createdAt: moment()
      .subtract(1, 'day')
      .toDate()
      .getTime(),
    description: 'A new player register for application',
    title: 'New Player registered',
    type: 'new_message'
  }];

mock.onGet('/api/notifications').reply(200, { notifications });
