/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";

import {
  Briefcase as BriefcaseIcon,
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Layers as LayersIcon,
  //Map as MapIcon,
  Grid as GridIcon,
  Save as SaveIcon,
  Server as ServerIcon,
  Codesandbox as CodesandboxIcon,
  DollarSign as DollarSignIcon,
} from "react-feather";
import Logo from "src/components/Logo";
import useAuth from "src/hooks/useAuth";
import NavItem from "./NavItem";



const { createMemoryHistory } = require("history");

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        chip={item.chip}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const history = createMemoryHistory();
  const { user } = useAuth();

  const userSections = [
    {
      subheader: "Monitor",
      items: [
        {
          title: "Dashboard",
          icon: BarChartIcon,
          href: "/app/reports/dashboard",
        }
      ],
    },
    {
      subheader: "Management",
      items: [
        {
          title: "Applications",
          icon: GridIcon,
          href: "/app/management/applications",
        },
        {
          title: "Leaderboards",
          icon: LayersIcon,
          href: "/app/management/leaderboards",
        },
        // {
        //   title: "Player Saves",
        //   icon: SaveIcon,
        //   chip: {
        //     text: "BETA",
        //     color: "primary"
        //   },
        //   href: "/app/saves",
        // },
        // {
        //   title: "Dynamic Configs",
        //   icon: ServerIcon,
        //   chip: {
        //     text: "BETA",
        //     color: "primary"
        //   },
        //   href: "/app/configs",
        // },
        {
          title: "NFTs",
          icon: CodesandboxIcon,
          href: "/app/nfts",
        },
      /*
        {
          title: "Players",
          icon: UsersIcon,
          href: "/app/management/players",
        },
      */
      ],
    }, {
      subheader: "Account",
      items: [
        {
          title: "Subscription",
          icon: DollarSignIcon,
          href: "/app/subscription",
        }
      ],
    },
  ]
  const adminSections = [
    {
      subheader: "Monitor",
      items: [
        {
          title: "Dashboard",
          icon: BarChartIcon,
          href: "/app/reports/dashboard",
        }
      ],
    },
    {
      subheader: "Management",
      items: [
        {
          title: "Players",
          icon: UsersIcon,
          href: "/app/management/players",
        },
      ],
    },
    {
      subheader: "Applications",
      items: [
        {
          title: "Game Applications",
          href: "/app/projects",
          icon: BriefcaseIcon,
          items: [
            {
              title: "Create New Report",
              href: "/app/projects/create",
            },
          ],
        },
      ],
    },
    {
      subheader: "Admin Only",
      items: [
        {
          title: "Crear Grupos",
          icon:  GridIcon,
          href: "/app/management/groups",
        },
        {
          title: "Gamebuddy Users",
          icon: UsersIcon,
          href: "/app/management/users",
        },
      ],
    },
  ];
  let sections =[]
  if (user.roles === "admin"){
    sections = adminSections
  }
  else{
    sections = userSections
  }
  useEffect(() => {
    console.log("permissions:",user)
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={5} display="flex" justifyContent="center">
            <RouterLink to="/app/reports/dashboard">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
            
              <Link component={RouterLink} to="/pricing">
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: history.location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography variant="body2" color="textSecondary">
              Getting Started?
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Link href="https://docs.gamebuddy.io">
                Documentation
              </Link>
            </Typography>
            <Box height={5}></Box>
            <Typography variant="body2" color="textSecondary">
              Unity Plugin
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Link href="https://gb-sdks.s3.amazonaws.com/gamebuddy_1_0.unitypackage">
                Download
              </Link>
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
