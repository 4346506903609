import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const { createMemoryHistory } = require("history");
const useScrollReset = () => {
  const history = createMemoryHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);
  return null;
};

export default useScrollReset;
