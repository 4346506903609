import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  text: {
    marginTop: theme.spacing(2)
  }
}));

const Contact = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Contact Us
        </Typography>
        <Typography
          className={clsx(classes.text)}
          variant="h3"
          align="center"
          color="textPrimary"
        >
          If you have any questions feel free to reach out to us by sending an email to support@gamebuddy.io
        </Typography>
      </Container>
    </div>
  );
};

Contact.propTypes = {
  className: PropTypes.string
};

export default Contact;
