import { createSlice } from "@reduxjs/toolkit";
//import { textSpanIntersectsWithTextSpan } from "typescript";
// import _ from "lodash";

const initialState = {
  cities: [],
  products: [],
  filters: {
    "categories": "",
    "colors": "",
    "minprice": 0,
    "maxprice": 0,
    "price_order": "asc",
    "deliveryType": "",
    "length": 0,
    "searchtxt": ""},
  basket: [],
  productQuantity: 1,
  singleproduct: {},
  categories:[]
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    getCities(state, action) {
      state.cities = action.payload;
    },
    fetchProducts(state, action) {
      state.products = action.payload;
    },
    productInBasket(state, action) {
      console.log(action.payload, "payload")
      let productCart = { ...action.payload, quantity: action.payload.quantity }
      // console.log(productCart,"redux cart")

      state.basket = [...state.basket, productCart]
    },
    productQuantity(state, action) {
      let quantity = action.payload === "increase" ? state.productQuantity + 1 : state.productQuantity - 1;
      state.productQuantity = quantity;
    },
    fetchProductDetail(state, action) {
      state.singleproduct = action.payload
    },
    setfilter(state, action) {
      state.filters = action.payload
    },
    setCategories(state, action) {
      state.categories = action.payload
    }
  },
});


export const getBasketTotal = (basket) => basket?.reduce((amount, item) => (item.product_price * item.quantity) + amount, 0);
export const reducer = homeSlice.reducer;
export default homeSlice;
