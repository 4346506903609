export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;
export const HOST = "https://api.gamebuddy.io";
//export const HOST = "http://localhost:3000"
export const HOST_BILLING = "https://billing.gamebuddy.io";
export const PRIVACY_POLICY_URL = "https://www.iubenda.com/privacy-policy/16427482";
export const EULA_URL = "https://www.iubenda.com/terms-and-conditions/16427482";
export const AFFILIATE_URL = "https://forms.gle/YHVxqjxuzPTH9LUr7";
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};
export const PLANS = new Map([
  ["Free", {
    price: 0,
    leaderboards: 3,
    games: 3,
    players: 100,
    freeCalls: 0,
    callPrice: 0,
  }],["Plus", {
    price: 9.99,
    leaderboards: 10,
    games: 10,
    players: 10000,
    freeCalls: 1000,
    callPrice: 0.008,
  }],["Premium", {
    price: 99.99,
    leaderboards: 0,
    games: 0,
    players: 0,
    freeCalls: 100000,
    callPrice: 0.004,
  }]
]);